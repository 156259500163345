$c-primary: #406bba;
$c-secandary: #406bba;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$body-color-overrided: #001e41;
$base-font-family: 'OpenSans', sans-serif;
$font-family-heading: 'OpenSans', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  :global .react-calendar {
    width: 300px;
  }

  .calendar {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  .closed {
    display: none;
  }
}

:global .react-calendar__tile {
  color: black;
}
