$c-primary: #406bba;
$c-secandary: #406bba;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$body-color-overrided: #001e41;
$base-font-family: 'OpenSans', sans-serif;
$font-family-heading: 'OpenSans', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  height: 100%;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.01875rem; //-0.332143px;



  .description {
    text-align: left;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
